@import "antd/dist/antd.less";
@import "@font/nunito";




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body-color {
  background-color: rgb(241, 241, 241);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: rgb(241, 241, 241);
}

.App-link {
  color: #0ba9d4;
}

.button-primary {
  background: #1CCFC9;
  height: 48px;
  width: 135px;
  left: 51px;
  top: 111px;
  border-radius: 30px;

}

.button-primary-right {
  float: right;
}


.logo-derco {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
  cursor: pointer;
}

.logo-derco-menu {
  padding-left: 4%;
  width: 160px;
  cursor: pointer;
}

.informacion-cliente {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
}

.logo-derco-footer {
  width: 256px;
}

.ant-table-thead {
  background-color: rgb(14, 9, 56);
}

.footer {
  text-align:end;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button-primary-right {
  float: right;
  background: white;
}

.anchor-primary-right {

  float: right;
  text-align: left;
}


.sider-derco {
  width: 256px;
}

.default-background {
  background: rgb(241, 241, 241);
}

.ant-menu-title-content {
  font-size: 16px;
}

.nombre-cliente {
  /* Titulos H4 */

  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #33518D;
}

td.ant-table-column-sort {
  background-color: transparent;
}



@media screen and (max-width: 896px) {
  .side-menu .ant-modal-content {
    height: 140% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

}



ant-modal-content .sin-rol-button {
  background-color: rgba(0, 37, 112, 1);
  border-radius: 25px;
  border-color: rgba(0, 37, 112, 1);
  width: 185px;
  height: 48px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #111A46;
    /* Green */
    color: white;
  }
}
/* .ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger{
  min-width: 256px !important;
} */

.side-menu-derco .ant-layout-sider .ant-layout-sider-light .ant-layout-sider-collapsed .ant-layout-sider-has-trigger {
  flex: 0 0 80px;
  max-width: 120px;
  min-width: 120px !important;
  width: 120px !important;
}

.ant-layout-content{
  min-height: 78vh !important;
}



@media (max-width: 896px) {
  .ant-layout-content{
    min-height: 78vh !important;
  }
}


@media (max-width: 376px) {
  .ant-layout-content{
    min-height: 65vh !important;
  }

}





.ant-table-thead th.ant-table-column-has-sorters:hover{
  background: rgb(248, 244, 244) !important;

}

.ant-table-thead > tr > th{
  background: rgb(241, 241, 241);
}


.ant-btn-primary-sorting {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 32px;
  
  position: absolute;
  left: 32.77%;
  right: 32.47%;
  top: 75.26%;
  bottom: 10.8%;
  color:#545454;
  background: #FFFFFF;
  border: 2px solid #545454;
  box-sizing: border-box;
  border-radius: 30px;
  }
.ant-layout{
  background: rgb(241, 241, 241);
}
.titulo-pictures {
  left: 0%;
  right: 52.47%;
  top: 0%;
  bottom: 0%;

  /* Titulos H2 */

  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;

  color: #000000;
  background: rgb(241, 241, 241);

}


.ant-table-thead > tr > th {
  font-style: normal;
  font-size: 18px;
  line-height: 50px;
  color: #000000;
}
.ant-table table {
  background: rgb(241, 241, 241);
}
.ant-table-cell{
  font-size: 17px;
}

/* background grillas */
.ant-table-tbody > tr > td {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 8px solid rgb(241, 241, 241);;

}

.ant-table-tbody > tr {
    border-radius: 10px;
    height: 65px;
  
  }

table {
   border-collapse: separate; 
}


/* Agrega bordes al primer y ultimo TD , tanto en arriba como abajo */
tr td:first-child { 
  border-top-left-radius: 5px;
}
tr td:first-child {
  border-bottom-left-radius:11px;
}
tr td:last-child {
  border-top-right-radius: 5px;
}
tr td:last-child {
  border-bottom-right-radius:11px;
}





/* Tooltip que utiliza la tabla de Pictures , para el background de los sort */
.ant-tooltip-open{
  background: rgb(241, 241, 241);
}

.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgb(255, 255, 255);

}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: rgb(255, 255, 255);

}


/* paginador , se oculta el select para ver cantidad de datos por pagina */
.ant-pagination-options-size-changer.ant-select{
  display:none;
}

.ant-layout-header{
  height: 75px;
}



.ant-upload-list-item-info
  > span
  > div[class="ant-upload-list-item-uploading-text"]:after {
  content: "Cargando";
  }

  /* background de alerta en pictures/cargar   */
  .ant-alert-banner{
    margin-bottom: 20px;
    background: #FFF7AD;
  }
  .ant-upload-picture-card-wrapper {
    margin-top: 50px;
  }
  .ant-row{
    padding-bottom: 10px;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    height: 48px;
    font-size: 18px;
  }

  .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button{
    height: 58px;
    width: 58px;
  }



/* Modifica el orden en que se muestra el boton upload fotos
  por defecto la libreria muestra este despliegue "FotaA - FotoB - FotoC .... BotonUpload"
  con el css implementado  muestra este despliegue "BotonUpload FotaA - FotoB - FotoC
*/
.ant-upload-list {
    display:flex;
    flex-direction: row; 
    flex-wrap: wrap;
}
.ant-upload-list-picture-card-container{
  order:1;
  margin-right: 25px;
}
.ant-upload-list-picture-card-container:first-child{
  order:1;
  margin-left: 18px;
}
.ant-upload.ant-upload-select-picture-card {
  border: 5px dashed #d9d9d9;
  border-radius: 10px;
  background-color: rgb(241 ,241 ,241);
}
.ant-upload.ant-upload-select-picture-card:hover {
  border: 5px dashed #04cfc4;
}
.ant-upload.ant-upload-select-picture-card {
  width: 215px;
  height: 215px;
}

.ant-upload-list-picture-card-container {
  width: 215px;
  height:   215px;
}


.sort-modal .ant-modal-content {
  height: 370px;
  border-radius: 15px;
 }
@media screen and (max-width: 650px) {
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height:   200px;

  }
  .ant-upload-list-picture-card-container {
    width:   200px;
    height:   200px;
  }
  .sort-modal .ant-modal-content {
    height: 340px;
    border-radius: 15px;
   }
}
.ant-modal-content {
  border-radius: 15px !important;
 }
 .ant-modal-body {
  padding:24px
 }
@media screen and (max-width: 896px) {
  .ant-upload.ant-upload-select-picture-card {
    width: 160px;
    height:   160px;
  }
  .ant-upload-list-picture-card-container {
    width:   160px;
    height:   160px;
  }
  .ant-modal-content {
    height: 500px;
    border-radius: 10px;
   }
   .ant-modal-body {
    padding-top: 26px;
    padding-right: 15px;
    padding-left: 15px;
   }


   .ant-upload-list-picture-card-container{
    order:1;
    margin-right: 0px;
  }
  .ant-upload-list-picture-card-container:first-child{
    order:1;
    margin-left: 0px;
  }





}
.ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 25px;

}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {

  padding: 0px 0px 0px 0px;

}
 .ant-upload-list-item-info:first-child {
  border-radius: 10px;
}



.ant-upload-list-item-info > span{
  width: 135%;
  height: 102%;
}


.ant-modal.ant-modal-confirm.ant-modal-confirm-info{
  margin-left: 8px;
  padding-top: 100px;
}


/* Icono eliminar foto */
.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn{
  display:block;
}


.input-search {
  width: 100%;
  padding: 15px;
  margin:4px;
  box-sizing: border-box;
  padding-left:60px;
 }

 .Icon-inside {
  position:relative;
 }

 .Icon-inside i {
  position:absolute;
  left:0;
  top:10px;
  padding:10px 10px;
  color:#30A3F1;
 }

 

.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 18px;
}
.ant-radio-checked .ant-radio-inner{
  border-color: rgba(51, 81, 141, 1)!important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: rgba(51, 81, 141, 1);
}

.ant-radio:hover .ant-radio-inner {
  border-color: rgba(51, 81, 141, 1) ;
}



.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav {
  color:"white" ;
  border-radius:"5px";
  background:"rgba(0, 37, 112, 1)";
}


.ant-tabs-tab{
  background-color: #F1F1F1 !important;

}

.ant-image{
  margin-right: 5px;
}


.example {
  margin: 12px;
}

/* Force update ant style */
.ant-input {
  padding: 16px 12px 4px 11px;
}

.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}

.anticon.anticon-check-circle{
  color:green !important;
}

.anticon.anticon-close-circle{
  color:red !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius:0px;
  border-top-left-radius:0px;

}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-right-radius:0px;
  border-top-left-radius:0px;

}

.ant-table-thead > tr > th {
  border-bottom: 0px;
  border-left: 0px;
}


.ant-breadcrumb span:last-child span:last-child {
  display: none;
}
.ant-breadcrumb {

  margin-top: 2.5%;

}

.ant-divider-horizontal{
  margin:5px
}

.button-text{
font-size: 18px;
font-weight: 450;
line-height: 25px;
letter-spacing: 0.04em;

}

.ant-table-column-sorter {

  color: black;

}


.ant-table-filter-trigger  {

  color: black;

}

.ant-card {
//styleName: Cuerpo 1;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(241, 241, 241, 1);
  ;
}


.text-menu-lateral{
  height: 22px;
width: 39px;
left: 48px;
top: 9.5px;
border-radius: nullpx;
font-size: 16px;
font-weight: 550;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}

.ant-page-header {
padding:0px 1.1%

}
@primary-color: rgba(51, 81, 141, 1);@link-color: #1890ff;@success-color: #52c41a;@warning-color:  #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08) ,0 9px 28px 8px rgba(0, 0, 0, 0.05);